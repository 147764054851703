<template>
    <div class="email">
        <Bread :title1="'Личный кабинет'" :title2="'E-mail рассылки'"/>  
        <div class="email__title">
            <p>E-mail рассылки </p>
        </div>
        <div class="email__sub">
            <p>Подпишитесь на E-mail расcылку, что-бы быть в курсе новостей магазина <span style="color: #006495;">HAYWARD</span></p>
        </div>
        <div class="email__input">
            <p>E-mail</p>
            <input type="email" v-model="v$.email.$model" :placeholder="$store.state.user.data.email"/>
            <template v-for="(error) of v$.email.$errors" :key="error">
              <p class="errorValid">{{ error.$message }}</p>
            </template>
        </div>
        <div class="email__heading">
            <div class="email__heading__title">
                <p>Рубрики:</p>
            </div>
            <div class="email__heading__checkboxes">
                <div class="email__heading__checkboxes__checkbox">
                    <input type="checkbox" v-model="is_news" true-value="1" false-value="0"/>
                    <p>Новости и магазин</p>
                </div>
                <div class="email__heading__checkboxes__checkbox">
                    <input type="checkbox" v-model="is_promotions" true-value="1" false-value="0"/>
                    <p>Продажи</p>
                </div>
                <div class="email__heading__checkboxes__checkbox">
                    <input type="checkbox" v-model="is_sales" true-value="1" false-value="0"/>
                    <p>Акции</p>
                </div>
            </div>
        </div>
        <div class="email__text">
            <p>После подписки Вам будет отправлено письмо со ссылкой подтверждения.</p>
        </div>
        <div class="email__check">
            <input type="checkbox" v-model="v$.check4.$model"/>
            <p>При отправке данной формы Вы подтверждаете свою дееспособность и согласие на <span style="color: #006495; text-decoration: underline;">обработку персональных данных</span></p>
            <template v-for="(error) of v$.check4.$errors" :key="error">
              <p class="errorValid">{{ error.$message }}</p>
            </template>
        </div>
        <div class="email__button">
            <button @click="sendData()" v-if="isActive == 0">
                Подписаться
            </button>
            <button v-if="isActive == 1">
                Отписаться
            </button>
        </div>
        <div class="email__save">
            <button @click="sendData()" v-if="isActive == 1">
                Сохранить
            </button>
        </div>
    </div>
</template>
<script>
import Bread from './Bread.vue';
import { useVuelidate } from '@vuelidate/core'
import { required, minLength, helpers, email, sameAs } from "@vuelidate/validators";
import { toast } from 'vue3-toastify';
import axios from "axios";
export default {
    components: {
        Bread
    },
    data() {
    return {
      v$: useVuelidate(),
      email: '',
      is_news: 0,
      is_promotions: 0,
      is_sales: 0,
      check4: '',
      isActive: 0
    }
  },
  validations() {
    return {
        email: {
            required: helpers.withMessage('Обязательное поле', required),
            email: helpers.withMessage('Некорректный email', email),
        },
        check4: {
            required: helpers.withMessage('Обязательное поле', required)
        },
    };
  },
  methods: {
    sendData(){
        if (!this.v$.$invalid) {
            const back = {
                email: this.email,
                is_news: this.is_news,
                is_sales:  this.is_sales,
                is_promotions: this.is_promotions
            };
            axios
                .post("https://back-hayward.mydev.kz/api/V1/user/subscriber/save", back, {
                headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
            })
                    .then((response) => {
                      if (response.status === 200) {
                        toast('Вы успешно подписались на E-mail расcылку. Мы вам отправили письмо со ссылкой подтверждения!')
                      } 
                      else {
                        toast(err?.response?.message)
                    }
                    })
                    .catch((e) => { 
                      console.log(e);
                    });
        }
        else{
            this.v$.$touch();
            toast.error('Заполните все обязательные поля!')
        }
    },
  },
  mounted(){
    if(this.$store.state.user.data.subscriber){
        this.is_news = this.$store.state.user.data.subscriber.is_news;
        this.is_promotions = this.$store.state.user.data.subscriber.is_promotions;
        this.is_sales = this.$store.state.user.data.subscriber.is_sales;
        this.isActive = this.$store.state.user.data.subscriber.is_active;
    }
  }
}
</script>
<style lang="scss" scoped>
.errorValid {
  font-size: 12px !important;
  color: #FF2A62 !important;
}
    .email{
        display: flex;
        flex-direction: column;
        gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 1.3021))), 30px);
        &__title{
            font-size: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.7813))), 24px);
            @media(max-width:768px){
                font-size: 15px;
            }
        }
        &__sub{
            color: #00000080;
            font-size: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.5208))), 18px);
            @media(max-width:768px){
                font-size: 14px;
            }
        }
        &__input{
            color: #006495;
            display: flex;
            flex-direction: column;
            gap: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.8681))), 20px);
            input{
                width: 20%;
                border: 1px solid #3636361A;
                padding: min(max(6px, calc(0.375rem + ((1vw - 7.68px) * 0.3472))), 10px);
                font-size: min(max(9px, calc(0.5625rem + ((1vw - 7.68px) * 0.5208))), 15px);
                outline: none;
                border-radius: 10px;
                @media(max-width:768px){
                    font-size: 13px;
                    width: 80%;
                }
            }
        }
        &__heading{
            display: flex;
            flex-direction: column;
            font-size: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.5208))), 18px);
            gap: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.8681))), 20px);
            @media(max-width:768px){
                font-size: 14px;
            }
            &__checkboxes{
                display: flex;
                gap: min(max(6px, calc(0.375rem + ((1vw - 7.68px) * 0.3472))), 10px);
                align-items: center;
                &__checkbox{
                    display: flex;
                    align-items: center;
                    gap: min(max(6px, calc(0.375rem + ((1vw - 7.68px) * 0.3472))), 10px);
                }
            }
        }
        &__text{
            color: #00000080;
            font-size: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.5208))), 18px);
            @media(max-width:768px){
                font-size: 14px;
            }
        }
        &__check{
            display: flex;
            gap: min(max(6px, calc(0.375rem + ((1vw - 7.68px) * 0.3472))), 10px);
            color: #36363680;
            align-items: center;
            font-size: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.5208))), 18px);
            @media(max-width:768px){
                font-size: 14px;
            }
        }
        &__button{
            width: 20%;
            @media(max-width:768px){
                width: 40%;
            }
            button{
                width: 100%;
                background: #006495;
                border: none;
                color: white;
                border-radius: 10px;
                font-size:  min(max(9px, calc(0.5625rem + ((1vw - 7.68px) * 0.5208))), 15px);
                @media(max-width:768px){
                    font-size: 14px;
                }
                padding: 8px;
                transition: all 0.5s ease; 
                cursor: pointer;
                &:hover{
                    background: white;
                    border: 1px solid #006495;
                    color: #006495;
                }
            }
        }
        &__save{
            width: 20%;
            @media(max-width:768px){
                width: 40%;
            }
            button{
                width: 100%;
                background: #119500;
                border: none;
                color: white;
                border-radius: 10px;
                padding: 8px;
                transition: all 0.5s ease; 
                cursor: pointer;
                font-size:  min(max(9px, calc(0.5625rem + ((1vw - 7.68px) * 0.5208))), 15px);
                @media(max-width:768px){
                    font-size: 13px;
                }
                &:hover{
                    background: white;
                    border: 1px solid #119500;
                    color: #119500;
                }
            }
        }
    }
</style>