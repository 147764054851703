<template>
    <div class="prophile">
        <Bread :title1="'Личный кабинет'" :title2="'Профиль заказов             '"/>  
        <div class="prophile__title">
            <p>Профили заказов</p>
        </div>
        <div class="prophile__products">
          <CardProduct :item="item" v-for="item in products" :key="item"/>
        </div>
    </div>
</template>
<script>
import Bread from './Bread.vue';
import CardProduct from './CardProduct.vue';
export default {
    data(){
        return{
             products: [
        {
          img: 'product1',
          title: 'Лайнер для бассейна Aquaviva Danube',
          articul: '2424',
          price: '126 500Т.'
        },
      ],
        }
    },
    components: {
        Bread, CardProduct
    }
}
</script>
<style lang="scss" scoped>
    .prophile{
        display: flex;
        flex-direction: column;
        gap: 30px;
        &__title{
            color: #363636;
            font-size: 24px;
        }
        &__products{
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        gap: 60px;
        row-gap: 50px;
            &__in{
                border: 1px solid #0000001A;
                border-radius: 10px;
                padding: 25px;
                display: flex;
                flex-direction: column;
                gap: 12px;
                font-size: 12px;
                color: #00000080;
                &__img{
                    width: 100%;
                    height: 150px;
                }
                &__title{
                    width: 60%;
                    color: #232F3E;
                }
                &__1{
                    display: flex;
                    gap: 10px;
                }
                &__price{
                    font-size: 16px !important;
                    color: black !important;
                }
                &__bot{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: red;
                }
            }
        }
    }
</style>    