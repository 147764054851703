<template>
    <div class="delivery container">
        <Table />
        <div class="delivery__right">
            <Bread :title1="'Главная'" :title2="'Доставка '" :link1="'/'"/>
            <div class="delivery__right__title">
                <p>Доставка</p>
            </div>
            <div class="delivery__right__block">
                <div class="delivery__right__block__top">
                    <div class="delivery__right__block__top__in" v-for="item in data.deliveryFeatures" :key="item.id">
                        <div class="delivery__right__block__top__in__img">
                            <img :src="item.image"/>
                        </div>
                        <div class="delivery__right__block__top__in__text">
                            <p>{{ item.title }}</p>
                        </div>
                    </div>
                </div>
                <div class="delivery__right__block__mid">
                    <div class="delivery__right__block__mid__in" v-for="item in data.deliveryLists" :key="item.id">
                        <div v-html="item.description">
                        </div>
                    </div>
                </div>
                <div class="delivery__right__block__bottom">
                    <div class="delivery__right__block__bottom__left">
                        <p v-html="data?.deliveryContent?.description"></p>
                    </div>
                    <div class="delivery__right__block__bottom__right">
                        <p v-html="data?.deliveryContent?.content"></p>
                    </div>
                </div>
            </div>
            <div class="pay__right__block">
                <div class="pay__right__block__top">
                    <div class="pay__right__block__top__left">
                        <img src="@/assets/icons/warn.svg"/>
                    </div>
                    <div class="pay__right__block__top__right">
                        <div class="pay__right__block__top__right__title">
                            <p>{{data1[0]?.title}}</p>
                        </div>
                        <div class="pay__right__block__top__right__text">
                            <p>{{data1[0]?.description}}</p>
                            <img :src="data1[0]?.image"/>
                        </div>
                    </div>
                </div>
                <div class="line"></div>
                <div class="pay__right__block__top">
                    <div class="pay__right__block__top__left">
                        <img src="@/assets/icons/warn.svg"/>
                    </div>
                    <div class="pay__right__block__top__right">
                        <div class="pay__right__block__top__right__title">
                            <p>{{data1[1]?.title}}</p>
                        </div>
                        <div class="pay__right__block__top__right__text">
                            <p>{{data1[1]?.description}}</p>
                            <img :src="data1[1]?.image"/>
                        </div>
                    </div>
                </div>  
            </div> 
        </div>
    </div>
</template>
<script>
import Table from '@/components/Table.vue';
import Bread from '@/components/Bread.vue';
import { mapMutations } from 'vuex';
export default {
    components: {
        Table, Bread
    },
    data(){
        return{
            data: false,
            data1: false
        }
    },
    async created() {
        this.getPage(),
        this.getPage1()
    },
    methods: {
    ...mapMutations(['SET_LOADING']),
      async getPage() {    
            this.SET_LOADING(true) 
            await this.$axios.get(`/pages/delivery?lang=ru`)
            .then(res => {
                this.data = res.data
                this.SET_LOADING(false) 
            })
            .catch(err => {
                this.SET_LOADING(false) 
            })        
        },
        async getPage1() {    
        this.SET_LOADING(true) 
            await this.$axios.get(`pages/payment?lang=ru`)
            .then(res => {
                this.data1 = res.data.paymentMethods
                this.SET_LOADING(false) 
            })
            .catch(err => {
                this.SET_LOADING(false) 
            })        
        },
    }
}
</script>
<style lang="scss" scoped>
.line{
    border: 1px solid #0000001A;
}
    .delivery{
        display: flex;
        justify-content: space-between;
        padding-top: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 1.1285))), 25px);
        padding-bottom: min(max(75px, calc(4.6875rem + ((1vw - 7.68px) * 6.5104))), 150px);
        &__right{
            width: 74.07%;
            display: flex;
            flex-direction: column;
            gap: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 1.1285))), 25px);
            height: fit-content;
            @media(max-width:768px){
                        width: 100%;
                    }
            &__title{
                font-size: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.6944))), 23px);
                font-weight: 600;
                @media(max-width:768px){
                    font-size: 16px;
                }
            }
            &__block{
                display: flex;
                flex-direction: column;
                gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 1.3021))), 30px);
                padding: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 1.3021))), 30px);
                border: 1px solid #3636361A;
                border-radius: 10px;
                @media(max-width:768px){
                        padding: 15px;
                        font-size: 13px;
                        gap: 10px;
                    }
                    &__top{
                        display: grid;
                        grid-template-columns: repeat(auto-fill, minmax(min(max(120px, calc(7.5rem + ((1vw - 7.68px) * 10.4167))), 240px), 1fr));
                        gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 1.3021))), 30px);
                        @media(max-width:768px){
                            display: flex;
                            flex-direction: column;
                        }
                        &__in{
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            text-align: center;
                            img{
                                height:  min(max(60px, calc(3.75rem + ((1vw - 7.68px) * 12.1528))), 200px);
                                width:  min(max(60px, calc(3.75rem + ((1vw - 7.68px) * 12.1528))), 200px);
                                @media(max-width:768px){
                                    width: 100px;
                                    height: 100px;
                                }
                            }
                            &__text{
                                font-size: min(max(11px, calc(0.6875rem + ((1vw - 7.68px) * 0.6076))), 18px);
                                @media(max-width:768px){
                                    font-size: 13px;
                                }
                            }
                        }
                    }
                    &__mid{
                        display: flex;
                        gap: min(max(30px, calc(1.875rem + ((1vw - 7.68px) * 2.6042))), 60px);
                        @media(max-width:768px){
                            display: none;
                        }
                        &__in{
                            border: 1px solid #006495;
                            padding: min(max(8px, calc(0.5rem + ((1vw - 7.68px) * 0.6076))), 15px) min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.8681))), 20px);
                            border-radius: 10px;
                            height: auto;
                            font-size: min(max(11px, calc(0.6875rem + ((1vw - 7.68px) * 0.6076))), 18px);
                        }
                    }
                    &__bottom{
                        display: flex;
                        justify-content: space-between;
                        font-size: min(max(11px, calc(0.6875rem + ((1vw - 7.68px) * 0.6076))), 18px);
                        @media(max-width:768px){
                            flex-direction: column;
                            gap: 20px;
                            padding-top: 20px;
                            font-size: 13px;
                        }
                        &__left{
                            width: 45%;
                            @media(max-width:768px){
                                width: 100%;
                                text-align: center;
                            }
                        }
                        &__right{
                            width: 45%;
                            @media(max-width:768px){
                                width: 100%;
                                text-align: center;
                            }
                        }
                    }
            }
        }
    }
    .pay{
       
        @media(max-width:768px){
                        padding-bottom: 20px;
                    }
        &__right{
            width: 74.07%;
            display: flex;
            flex-direction: column;
            gap: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 1.1285))), 25px);
            @media(max-width:768px){
                        width: 100%;
            }
            &__block{
                border: 1px solid #0000001A; 
                padding: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px) min(max(80px, calc(5rem + ((1vw - 7.68px) * 17.8819))), 286px)  min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px) min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 1.3021))), 30px);
                display: flex;
                flex-direction: column;
                gap: min(max(20px, calc(1.25rem + ((1vw - 7.68px) * 1.7361))), 40px);
                @media(max-width:768px){
                        padding: 20px;
                        gap: 15px;
                        border-radius: 10px;
                }
                &__top{
                    display: flex;
                    gap: 10px;
                    &__left{
                        img{
                            width:  min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 1.1285))), 25px);
                            height:  min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 1.1285))), 25px);
                            @media(max-width:768px){
                                width: 20px;
                                height: 20px;
                            }
                        }
                    }
                    &__right{
                        display: flex;
                        flex-direction: column;
                        gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 1.3021))), 30px);
                        width: 90%;
                        @media(max-width:768px){
                            width:95%;
                            gap: 15px;
                        }
                        &__title{
                            font-size: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.8681))), 20px);
                            @media(max-width:768px){
                                font-size: 15px;
                            }
                        }
                        &__text{
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            color: #36363680;
                            font-size: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.8681))), 20px);
                            img{
                                width: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 3.0382))), 60px);
                                height: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.9097))), 40px);
                                @media(max-width:768px){
                                    width: 35px;
                                    height: 27px;
                                }
                            }
                            p{
                                width: 80%;
                            }
                            @media(max-width:768px){
                                gap: 15px;
                                font-size: 15px;
                            }
                        }
                    }
                }
            }
        }
    }
</style>