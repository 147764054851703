<template>
    <div class="diller container">
        <Table />
        <div class="diller__right">
            <Bread :title1="'Главная'" :title2="'Диллерам '" :link1="'/'"/>
            <div class="diller__right__title">
                <p>Дилерам</p>
            </div>
            <div class="diller__right__block">
                <div class="diller__right__block__text">
                    <p>{{ data.description }}</p>
                </div>
                <div class="line">
                </div>
                <div class="diller__right__block__info">
                    <div class="diller__right__block__info__top">
                        <img src="@/assets/icons/gmail.svg"/>
                        <p>{{ data.email }}</p>
                    </div>
                    <div class="diller__right__block__info__bot">
                        <a :href="`tel:${data.phone}`" style="color: inherit; text-decoration: none;"><img src="@/assets/icons/call1.svg"/></a>
                        <p><a :href="`tel:${data.phone}`" style="color: inherit; text-decoration: none;">{{data.phone}}</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Table from '@/components/Table.vue';
import Bread from '@/components/Bread.vue';
import { mapMutations } from 'vuex';
export default {
    data(){
        return{
            data: false,
        }
    },
    components: {
       Table, Bread
    },
    async created() {
          this.getPage()    
      },
      methods: {
        ...mapMutations(['SET_LOADING']),
        async getPage() {    
            this.SET_LOADING(true) 
              await this.$axios.get(`pages/dealer?lang=ru`)
              .then(res => {
                  this.data = res.data.dealerContent
                  this.SET_LOADING(false) 
              })
              .catch(err => {
                this.SET_LOADING(false) 
              })        
          },
      }
}
</script>
<style lang="scss" scoped>
.line{
    border: 1px solid #0000001A;
}
    .diller{
        display: flex;
        justify-content: space-between;
        padding-top: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 1.1285))), 25px);
        padding-bottom: min(max(75px, calc(4.6875rem + ((1vw - 7.68px) * 6.5104))), 150px);
        &__right{
            width: 74.07%;
            display: flex;
            flex-direction: column;
            gap: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 1.1285))), 25px);
            @media(max-width:768px){
                width: 100%;
            }
            &__title{
                font-size: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.6944))), 23px);
                font-weight: 600;
                @media(max-width:768px){
                    font-size: 17px;
                }
            }
            &__block{
                border: 1px solid #3636361A;
                padding: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 1.3021))), 30px) min(max(60px, calc(3.75rem + ((1vw - 7.68px) * 12.1528))), 200px) min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 1.3021))), 30px) min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 1.3021))), 30px);
                display: flex;
                border-radius: 10px;
                justify-content: space-between;
                align-items: stretch;
                @media(max-width:768px){
                    flex-direction: column;
                    padding: 20px;
                    gap: 25px;
                }
                &__text{
                    width: 40%;
                    font-size: min(max(11px, calc(0.6875rem + ((1vw - 7.68px) * 0.6076))), 18px);
                    line-height: min(max(20px, calc(1.25rem + ((1vw - 7.68px) * 1.7361))), 40px);
                    @media(max-width:768px){
                        width: 100%;
                        line-height: 25px;
                        font-size: 15px;
                    }
                }
                &__info{
                    display: flex;
                    flex-direction: column;
                    gap: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 1.1285))), 25px);
                    justify-content: center;
                    font-size: min(max(11px, calc(0.6875rem + ((1vw - 7.68px) * 0.6076))), 18px);
                    @media(max-width:768px){
                        gap: 15px;
                        font-size: 15px;
                    }
                    &__top{
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        img{
                            width: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 1.3021))), 30px);
                            height: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 1.3021))), 30px);
                        }
                    }
                    &__bot{
                        display: flex;
                        align-items: center;
                        gap: 10px;  
                        img{
                            width: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 1.3021))), 30px);
                            height: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 1.3021))), 30px);
                        }
                    }
                }
            }

        }
    }
</style>