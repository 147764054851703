<template>
    <div class="desctop">
        <div class="bread">
            <p @click="handleRouterPush(link1)">{{ title1 }}</p>
            <div class="line"></div>
            <p v-if="title2" :class="{ activeBread: title3 == null }" @click="handleRouterPush(link2)">{{ title2 }}</p>
            <div class="line" v-if="title3 != null"></div>
            <p v-if="title3" :class="{ activeBread: title4 == null }" @click="handleRouterPush(link3)">{{ title3 }}</p>
            <div class="line" v-if="title4 != null"></div>
            <p style="color: #0076FF;" v-if="title4">{{ title4 }}</p>
        </div>
    </div>
</template>
<script>
export default {
    props: ['title1', 'title2', 'title3', 'title4' , 'link1', 'link2', 'link3'],
    methods: {
        handleRouterPush(link) {
            if (link) {
            this.$router.push(link);
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.activeBread{
    color: #0076FF;
}
.line{
    border: 1px solid #0000001A;
}
    .bread{
        display: flex;
        gap: 10px;
        color: #00000080;
        font-size: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.5208))), 18px);
        p{
            cursor: pointer;
        }
    }
</style>