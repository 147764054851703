<template>
    <div class="payBack animated" @click="$store.state.openPay = false">
        <div class="pay animated animateModal" @click.stop>
            <div class="pay__title">
                <p>Заполните данные</p>
            </div>
            <div class="pay__inputs">
                <div class="pay__inputs__input">
                    <p class="pay__inputs__input__name">Наименование организации*</p>
                    <input type="text" v-model="v$.name.$model"/>
                    <template v-for="(error) of v$.name.$errors" :key="error">
                        <p class="errorValid">{{ error.$message }}</p>
                    </template>
                </div>
                <div class="pay__inputs__input">
                    <p class="pay__inputs__input__name">БИН/ИИН*</p>
                    <input type="text" v-model="v$.bin.$model"/>
                    <template v-for="(error) of v$.bin.$errors" :key="error">
                        <p class="errorValid">{{ error.$message }}</p>
                    </template>
                </div>
                <div class="pay__inputs__input">
                    <p class="pay__inputs__input__name">E-maill*</p>
                    <input type="email" v-model="v$.email.$model"/>
                    <template v-for="(error) of v$.email.$errors" :key="error">
                        <p class="errorValid">{{ error.$message }}</p>
                    </template>
                </div>
                <div class="pay__inputs__input">
                    <p class="pay__inputs__input__name">Номер телефона*</p>
                    <input type="phone" v-model="v$.phone.$model" v-mask="'+7 (###) ###-##-##'" placeholder="+7 (000) 000-00-00"/>
                    <template v-for="(error) of v$.phone.$errors" :key="error">
                        <p class="errorValid">{{ error.$message }}</p>
                    </template>
                </div>
                <div class="pay__inputs__input">
                    <p class="pay__inputs__input__name">Юридический адресс*</p>
                    <input type="text" v-model="v$.address_1.$model"/>
                    <template v-for="(error) of v$.address_1.$errors" :key="error">
                        <p class="errorValid">{{ error.$message }}</p>
                    </template>
                </div>
                <div class="pay__inputs__input">
                    <p class="pay__inputs__input__name">Фактический адресс*</p>
                    <input type="text" v-model="v$.address_2.$model"/>
                    <template v-for="(error) of v$.address_2.$errors" :key="error">
                        <p class="errorValid">{{ error.$message }}</p>
                    </template>
                </div>
            </div>
            <div class="pay__check">
                <div>
                    <input type="checkbox" v-model="v$.check.$model"/>
                    <template v-for="(error) of v$.check.$errors" :key="error">
                            <p class="errorValid">{{ error.$message }}</p>
                    </template>
                </div>
                <p>При отправке данной формы Вы подтверждаете свою дееспособность и согласие на <span style="color:#006495 ; text-decoration: underline;">обработку персональных данных</span></p>
            </div>
        <div class="pay__button">
                <button style="cursor: pointer;" @click="sendData()">Оформить заказ</button>
        </div>
        </div>
    </div>
</template>
<script>
import { useVuelidate } from '@vuelidate/core'
import { required, minLength, email, helpers, numeric, maxLength } from '@vuelidate/validators'
import { mask } from "vue-the-mask";
import { mapGetters, mapMutations } from 'vuex';
import axios from "axios";
export default {
    setup () {
        return { v$: useVuelidate() }
    },
    directives: { mask },
    props: [
        'selectPayer',
        'selectDeliever'
    ],
    data(){
        return{
            name: '',
            bin: '',
            email: '',
            phone: '',
            address_1: '',
            address_2: '',
            check: '',
            simplifiedBasket: '',
        }
    },
    computed: {
    ...mapGetters(['getBasket']),
    },
    validations() {
    return {
      name: {
          required: helpers.withMessage('Обязательное поле', required),
          minLength: helpers.withMessage('Слишком короткое имя', minLength(4)
          ),
      },
      bin: {
        required: helpers.withMessage('Обязательное поле', required),
        numeric: helpers.withMessage('Введите цифры', numeric),
        minLength: helpers.withMessage('12 символов', minLength(12)),
        maxLength: helpers.withMessage('12 символов', maxLength(12))
      },
      phone: {
        required: helpers.withMessage('Обязательное поле', required),
      },
      email: {
        required: helpers.withMessage('Обязательное поле', required), 
        email: helpers.withMessage('Неправильный формат' , email)
      },
      address_1: {
        required: helpers.withMessage('Обязательное поле', required),
      },
      address_2: {
        required: helpers.withMessage('Обязательное поле', required),
      },
      check : {
        required: helpers.withMessage('Обязательное поле', required),
      }
    };
  },
  mounted() {
    // Создание упрощенного массива при монтировании компонента
    this.simplifiedBasket = this.getBasket.map(item => ({
      id: item.id,
      quantity: item.counter
    }));
  },
  methods: {
    ...mapMutations(['DELETE_All_BASKET']),
    sendData() {
        if (!this.v$.$invalid) {
            const back = {
                user_type: this.selectPayer,
                delivery_type: this.selectDeliever,
                organization_name: this.name,
                organization_bin: this.bin,
                organization_email: this.email,
                organization_phone: this.phone,
                organization_legal_address: this.address_1,
                organization_current_address: this.address_2,
                orderProducts: this.simplifiedBasket
            };
            axios
                .post("https://back-hayward.mydev.kz/api/V1/orders/create", back, {
                headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
            })
                    .then((response) => {
                      if (response.status === 201) {
                        this.name = '',
                        this.bin = '';
                        this.phone = '';
                        this.email = '';
                        this.address_1 = '';
                        this.address_2 = '';
                        this.$store.state.openBasket = 'issued2';
                        this.$store.state.openPay = false;
                        this.DELETE_All_BASKET();
                      } 
                      else {
                        toast(err?.response?.message)
                    }
                    })
                    .catch((e) => { 
                      console.log(e);
                    });
        }
        else{
            this.v$.$touch();
        }
    }
  }

}
</script>
<style lang="scss" scoped>
.errorValid{
  color: red;
  font-size: 10px;
}

.fade-enter-active,
.fade-leave-active {
  animation: fade-in 3s;
  -webkit-animation: fade-in 3s;
}

@keyframes fade-in {
    from {
        transform: scale(0.5);
        -webkit-transform: scale(0.5);
        -moz-transform: scale(0.5);
        -ms-transform: scale(0.5);
        -o-transform: scale(0.5);
    }
    to {
        transform: scale(1);
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
}
}


    .payBack{
        position: fixed;
        z-index: 999;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .pay{
        background: white;
        width: 30%;
        padding: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px);
        display: flex;
        border-radius: 20px;
        flex-direction: column;
        gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 1.3021))), 30px);
        @media(max-width:768px){
            width: 90%;
        }
        &__title{
            font-size: min(max(16px, calc(1rem + ((1vw - 7.68px) * 1.2153))), 30px);
            text-align: center;
            @media(max-width:768px){
                font-size: 19px;
            }
        }
        &__inputs{
            display: flex;
            flex-direction: column;
            gap: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 1.1285))), 25px);
            align-items: center;
            @media(max-width:768px){
                gap: 15px;
            }
            &__input{
                width: 80%;
                display: flex;
                flex-direction: column;
                gap: min(max(6px, calc(0.375rem + ((1vw - 7.68px) * 0.3472))), 10px);
                font-size: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.3472))), 16px);
                @media(max-width:768px){
                    font-size: 12px;
                    gap: 6px;
                }
                input{
                    border: 1px solid #36363626;
                    border-radius: 10px;
                    width: 100%;
                    padding: 8px;
                    outline: none;
                    font-size: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.3472))), 16px);
                }
            }
        }
        &__check{
            display: flex;
            gap:  min(max(8px, calc(0.5rem + ((1vw - 7.68px) * 0.6076))), 15px);
            color: #36363680;
            font-size: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.3472))), 16px);
            @media(max-width:768px){
                font-size: 12px;
                gap: 10px;
            }
        }
        &__button{
            width: 50%;
            margin-left: auto;
            margin-right: auto;
            button{
                width: 100%;
                background: #006495;
                color: white;
                padding: 10px 0px;
                border: none;
                border-radius: 10px;
                font-size: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.3472))), 16px);
                @media(max-width:768px){
                    font-size: 13px;
                }
            }
        }
    }


</style>