<template>
  <div class="container">
    <div class="bread">
      <Bread :title1="'Главная'" :title2="'Страницы'" :link1="'/'" />
    </div>
  </div>
  <div v-for="item in sliderData">
    <div class="block_1">
      <img :src="`${item.page_background_image}`" alt="img in pages" />
      <div class="block_1-bg"></div>
      <div class="block_1-content" v-html="item.page_title"></div>
    </div>
    <div class="block_2" v-if="item.second_title !== '' && item.second_title !== null">
      <div class="block_2-cont">
        <article v-html="item.second_title"></article>
        <img :src="`${item.page_second_title_image}`" alt="page_subtitle_image" />
      </div>
    </div>
    <div class="block_3" v-if="item.video_url !== '' && item.video_url !== null">
      <div class="block_3-cont">
        <article>
          <h1>{{ item.third_title }}</h1>
          <p>{{ item.page_third_subtitle }}</p>
        </article>
        <iframe :src="`${item.video_url}`" frameborder="0"></iframe>
      </div>
    </div>
    <div class="block_4" v-if="item.blocks !== null">
      <div class="block_4-cont">
        <div v-for="(i, index) in item.blocks" :key="index">
          <section v-if="index % 2 === 1">
            <article class="block_4-cont__text">
              <h1>{{ i.title }}</h1>
              <p v-html="i.content"></p>
            </article>
            <img :src="`${i.image}`" :alt="`${i.title}`" class="block_4-cont__img" />
          </section>
          <section v-if="index % 2 === 0">
            <img :src="`${i.image}`" :alt="`${i.title}`" class="block_4-cont__img" />
            <article>
              <h1>{{ i.title }}</h1>
              <p v-html="i.content"></p>
            </article>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Bread from "@/components/Bread.vue";
import { mapGetters } from "vuex";
export default {
  components: { Bread },
  data() {
    return {
      sliderData: null,
    };
  },
  async created() {
    this.currentPage = this.$route.query.page ? parseInt(this.$route.query.page) : 1;
    this.getPage();
  },
  methods: {
    ...mapGetters(["SET_LOADING"]),
    async getPage() {
      this.SET_LOADING(true);
      this.id = Number(this.$route.params.id);
      await this.$axios
        .get(`pages/slider/${this.id}`)
        .then((res) => {
          this.sliderData = res.data.sliders;
          console.log(this.sliderData);
          this.SET_LOADING(false);
        })
        .catch((err) => {
          this.SET_LOADING(false);
        });
    },
  },
};
</script>

<style scoped lang="scss">
.bread {
  margin: 2rem 0;
}
.block_1 {
  width: 100%;
  height: 425px;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
  }
  &-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
    background: linear-gradient(77.56deg, #045c8a 0%, rgba(5, 113, 173, 0) 71.36%);
    top: 0;
  }
  &-content {
    position: absolute;
    z-index: 100;
    top: 10rem;
    left: 10rem;
    color: white;
    width: 70%;
    @media (max-width: 425px) {
      top: 0;
      left: 2rem;
      width: 100%;
    }
    h1 {
      font-size: 10px;
      font-weight: 900;
      h1 {
        span {
          span {
            @media (max-width: 425px) {
              font-size: 20px;
              font-weight: 600;
            }
          }
        }
      }
    }
    p {
      font-size: 20px;
      font-weight: 400;
    }
  }
}
.block_2 {
  width: 100%;
  height: fit-content;
  padding: 4rem 0;
  background-color: #f8f8f8;
  color: #181818;
  margin-top: 3rem;
  &-cont {
    width: 60%;
    margin: 0 auto;
    article {
      display: flex;
      gap: 1rem;
      flex-direction: column;
      p {
        font-weight: 700;
        font-size: 20px;
        text-align: center;
      }
      h1 {
        font-weight: 900;
        font-size: 3rem;
        text-align: center;
      }
    }
    img {
      width: 100%;
      height: 3rem;
    }
  }
}
.block_3 {
  margin-top: 3rem;
  color: #181818;
  &-cont {
    padding: 3rem 0;
    margin: 0 auto;
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3rem;
    @media (max-width: 768px) {
      width: 90%;
    }
    article {
      h1 {
        font-size: 3rem;
        font-weight: 900;
      }
      p {
        font-size: 1rem;
        font-weight: 400;
        text-align: center;
      }
    }
    iframe {
      width: 1000px;
      height: 564px;
      @media (max-width: 1023px) {
        width: 700px;
        height: 400px;
      }
      @media (max-width: 768px) {
        width: 100%;
        height: 400px;
      }
    }
  }
}
.block_4 {
  color: #181818;
  margin-bottom: 7rem;
  @media (max-width: 425px) {
    margin-bottom: 2rem;
  }
  &-cont {
    width: 70%;
    margin: 0 auto;
    @media (max-width: 1440px) {
      width: 85%;
    }
    @media (max-width: 1024px) {
      width: 85%;
    }
    section {
      padding: 2rem 0;
      display: flex;
      justify-content: space-between;
      @media (max-width: 425px) {
        flex-direction: column;
        gap: 1rem;
      }
      .block_4-cont__text {
        align-items: start;
      }
      article {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        @media (max-width: 425px) {
          width: 100%;
        }
        h1 {
          width: 500px;
          font-size: 38px;
          font-weight: 900;
          @media (max-width: 1024px) {
            width: 85%;
            font-size: 30px;
          }
          @media (max-width: 425px) {
            width: 100%;
            text-align: center;
          }
        }
        p {
          width: 500px;
          font-size: 1rem;
          font-weight: 400;
          @media (max-width: 1024px) {
            width: 85%;
          }
          @media (max-width: 425px) {
            width: 100%;
            text-align: center;
          }
        }
        span {
          font-size: 1rem;
          font-weight: 400;
          font-style: italic;
          color: #94979c;
        }
      }
      img {
        width: 50%;
        height: 370px;
        object-fit: cover;
        border-radius: 1rem;
        @media (max-width: 1024px) {
          height: auto;
        }
        @media (max-width: 425px) {
          width: 100%;
        }
      }
    }
  }
}
</style>
