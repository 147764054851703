<template>
  <div class="catalog container">
    <Table @category-selected="handleCategorySelection" :filters="newFilters" />
    <div class="catalog__right">
      <Bread :title1="'Каталог товаров'" :title2="selectedCategory" />
      <div class="catalog__right__title">
        <p>Каталог товаров</p>
        <div class="mobile">
          <div style="display: flex; gap: 5px" @click="$store.state.openTable = !$store.state.openTable">
            <img src="@/assets/icons/bluefilter.svg" />
            <p style="color: #4098ff">Фильтр</p>
          </div>
        </div>
      </div>
      <!-- <div class="catalog__right__values">
                <div v-for="item in newCategories" :key="item" @click="$store.state.categoryId = item.id" style="cursor: pointer;" :class="{activeCategory: item.id == $store.state.categoryId}">
                    <p>{{ item.title }}</p>
                </div>
            </div> -->
      <div class="catalog__right__products" v-if="newProducts.length > 0">
        <CardProduct :item="item" v-for="item in newProducts" :key="item" />
      </div>
      <div class="catalog__right__not" v-else>
        <p>Нет совпадений</p>
      </div>
      <div class="catalog__right__bottom">
        <vue-awesome-paginate v-if="pageCount" :total-items="10 * pageCount" v-model="currentPage" @click="pageChanged" />
      </div>
    </div>
  </div>
</template>
<script>
import Table from "@/components/Table.vue";
import Bread from "@/components/Bread.vue";
import CardProduct from "@/components/CardProduct.vue";
import { mapMutations } from "vuex";
export default {
  components: {
    Table,
    Bread,
    CardProduct,
  },
  data() {
    return {
      newProducts: false,
      newCategories: false,
      currentPage: 1,
      newFilters: false,
      pageCount: "",
      selectedCategory: "Все товары",
    };
  },
  async created() {
    this.currentPage = this.$route.query.page ? parseInt(this.$route.query.page) : 1;
    this.getPage();
  },
  methods: {
    ...mapMutations(["SET_LOADING"]),
    async pageChanged(page) {
      const currentQuery = { ...this.$route.query };
      currentQuery.page = page;
      this.$router.push({ path: "/catalog", query: { ...currentQuery } });
      this.getPage();
    },
    handleCategorySelection(category) {
      // Добавить выбранную категорию в список выбранных категорий
      this.selectedCategory = category;
    },
    async getPage() {
      this.SET_LOADING(true);
      const sendd = {};
      for (const key in this.$route.query) {
        if (Object.hasOwnProperty.call(this.$route.query, key)) {
          const element = this.$route.query[key];
          if (key === "feature_item_ranges") {
            sendd[key] = JSON.parse(element);
          } else {
            sendd[key] = element;
          }
        }
      }
      await this.$axios
        .get(`pages/catalog`, {
          params: sendd,
        })
        .then((res) => {
          this.newFilters = res.data.filters;
          this.newProducts = res.data.products.data;
          this.newCategories = res.data.categories;
          this.pageCount = res.data.products.last_page;
          this.SET_LOADING(false);
        })
        .catch((err) => {
          this.SET_LOADING(false);
        });
    },
  },
  watch: {
    $route() {
      this.$store.state.categoryId = "";
    },
    "$route.query": {
      handler: "getPage",
      deep: true,
    },
    "$route.query.category_id": {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          // Параметр category_id изменился, устанавливаем this.currentPage в нужное значение
          this.currentPage = 1; // Например, установить текущую страницу в значение параметра category_id
        }
      },
      deep: true,
    },
    "$route.query.sub_category_id": {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          // Параметр category_id изменился, устанавливаем this.currentPage в нужное значение
          this.currentPage = 1; // Например, установить текущую страницу в значение параметра category_id
        }
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.activeCategory {
  border: 1px solid #006495 !important;
  color: #006495 !important;
}
.catalog {
  display: flex;
  justify-content: space-between;
  padding: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 1.1285))), 25px) 0px min(max(80px, calc(5rem + ((1vw - 7.68px) * 6.0764))), 150px)
    0px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  &__right {
    width: 74.07%;
    display: flex;
    flex-direction: column;
    gap: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 1.1285))), 25px);
    @media (max-width: 768px) {
      width: 100%;
    }
    &__title {
      display: flex;
      justify-content: space-between;
      font-size: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.6944))), 23px);
      font-weight: 700;
      @media (max-width: 768px) {
        font-size: 15px;
      }
    }
    &__values {
      display: flex;
      flex-wrap: wrap;
      gap: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 1.1285))), 25px);
      font-size: min(max(8px, calc(0.5rem + ((1vw - 7.68px) * 0.3472))), 12px);
      div {
        padding: 6px min(max(7px, calc(0.4375rem + ((1vw - 7.68px) * 0.5208))), 13px);
        border: 1px solid #36363680;
        border-radius: 5px;
        color: #36363680;
      }
    }
    &__bottom {
      margin-right: auto;
      margin-left: auto;
    }
    &__products {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(min(max(150px, calc(9.375rem + ((1vw - 7.68px) * 8.6806))), 250px), 1fr));
      row-gap: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px);
      font-size: min(max(9px, calc(0.5625rem + ((1vw - 7.68px) * 0.6076))), 16px);
      @media (max-width: 768px) {
        row-gap: 20px;
        grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
      }
    }
    &__not {
      font-size: min(max(9px, calc(0.5625rem + ((1vw - 7.68px) * 0.6076))), 16px);
      @media (max-width: 768px) {
        font-size: 13px;
      }
    }
  }
}
</style>
