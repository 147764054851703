<template>
  <div class="desctop">
    <div class="headerBack">
      <div class="header container">
        <div class="header__left">
          <div class="header__left__info">
            <img src="@/assets/icons/email1.svg" />
            <p>{{ header.headerContact?.email }}</p>
          </div>
        </div>
        <div class="header__right">
          <div class="header__right__1">
            <p>Контакты:</p>
            <p class="hoveredElements"><a :href="`tel:${header.headerContact?.phone}`" style="color: inherit; text-decoration: none;">{{ header.headerContact?.phone }}</a></p>
          </div>
        </div>
      </div>
    </div>
    <div class="info container" v-if="$route.meta.info == false">
      <div class="info__left">
        <router-link to="/"><img src="@/assets/icons/logo.svg" /></router-link>
      </div>
      <div class="info__right">
        <div class="info__right__top">
          <div class="cont">
            <div class="tabs">
              <input type="radio" id="radio-1" name="tabs" @click="$router.push('/')" :checked="$route.path === '/'">
              <label class="tab" for="radio-1">Главная</label>
              <input type="radio" id="radio-2" name="tabs" @click="$router.push('/catalog')"
                :checked="$route.path === '/catalog'">
              <label class="tab" for="radio-2">Каталог</label>
              <input type="radio" id="radio-3" name="tabs" @click="$router.push('/dealers')"
                :checked="$route.path === '/dealers'">
              <label class="tab" for="radio-3">Дилеры</label>
              <input type="radio" id="radio-4" name="tabs" @click="$router.push('/about')"
                :checked="$route.path === '/about'">
              <label class="tab" for="radio-4">О нас</label>
              <input type="radio" id="radio-5" name="tabs" @click="$router.push('/delivery')"
                :checked="$route.path === '/delivery'">
              <label class="tab" for="radio-5">Доставка</label>
              <input type="radio" id="radio-6" name="tabs" @click="$router.push('/contacts')"
                :checked="$route.path === '/contacts'">
              <label class="tab" for="radio-6">Контакты</label>
              <span class="glider"></span>
            </div>
          </div>
          <div class="info__right__top__icons">
            <div class="info__right__top__icons__search">
              <div class="info__right__top__icons__search__main">
                <input type="search" placeholder="Search" @keydown.enter="searchODIT" v-model="searchQuery">
                <button type="submit" @click="searchODIT"></button>
              </div>
            </div>
            <div class="heart">
              <img class="heart__img" src="@/assets/icons/heart2.svg" style="cursor: pointer;"
                @click="$router.push('/account'), $store.state.openAcc = 'favourites'" />
              <div class="heart__in">{{ getLiked.length }}</div>
            </div>
            <div class="basket">
              <img src="@/assets/icons/basket.svg" style="cursor: pointer;" class="basket__img"
                @click="$router.push('/account'), $store.state.openAcc = 'basket'" />
              <div class="basket__in">{{ getBasket.length }}</div>
            </div>
            <div class="info__right__top__icons__auth" v-if="$store.state.auth == false">
              <img src="@/assets/icons/out.svg" @click="openModalLogin = !openModalLogin" style="cursor: pointer;" />
              <div class="info__right__top__icons__auth__in" v-if="openModalLogin == true">
                <router-link to="/login" style="text-decoration: none; color: inherit;">
                  <p>Войти</p>
                </router-link>
                <router-link to="/register" style="text-decoration: none; color: inherit;">
                  <p>Регистрация</p>
                </router-link>
              </div>
            </div>
            <div class="info__right__top__icons__auth" v-if="$store.state.auth == true">
              <img :src="$store.state.user?.data?.photo" @click="openModalLogin = !openModalLogin" class="profileImg"
                style="cursor: pointer;" />
              <div class="info__right__top__icons__auth__in" v-if="openModalLogin == true">
                <p>{{ $store.state.user.data.first_name }}</p>
                <router-link to="/account" style="text-decoration: none; color: inherit;">
                  <p @click="$store.state.openAcc = 'personal', openModalLogin = false">Личный кабинет</p>
                </router-link>
                <p @click="leaveAccount">Выйти</p>
              </div>
            </div>
          </div>
        </div>
        <div class="line"></div>
      </div>
    </div>
  </div>
  <div class="mobile">
    <div class="mobileHeader">
      <div class="mobileHeaderFixed" ref="mobileHeaderFixed">
        <div class="mobileHeader__topBack">
        <div class="mobileHeader__top container">
        <div class="mobileHeader__top__left">
          <div class="mobileHeader__top__left__burger">
            <img src="@/assets/icons/burger.svg" @click="$store.state.openBurger = !$store.state.openBurger"/>
          </div>
        </div>
          <div class="mobileHeader__centerBack">
            <div class="mobileHeader__center container">
              <a :href="'tel:' + header.headerContact?.phone"><img src="@/assets/icons/callwhite.svg" style="width: 20px; height: 20px;"/></a>
              <div class="line1"></div>
              <div class="basket">
                <img src="@/assets/icons/basketwhite.svg"  @click="$router.push('/account'), $store.state.openAcc = 'basket'" style="width: 20px; height: 20px;"/>
                <div class="basket__inMobile">{{ getLiked.length }}</div>
              </div>
              <div class="line1"></div>
              <div class="heart">
                  <img class="heart__img" src="@/assets/icons/heartwhite.svg" style="cursor: pointer;"
                    @click="$router.push('/account'), $store.state.openAcc = 'favourites'" />
                  <div class="heart__inMobile">{{ getLiked.length }}</div>
                </div>
              <div class="line1"></div>
              <img :src="$store.state.user?.data?.photo" v-if="$store.state.auth == true" style="width: 30px; height: 30px; object-fit: cover; border-radius: 50%;"
              @click="$router.push('/account'), $store.state.openAcc = 'personal'"/>
              <img src="@/assets/icons/prophilewhite.svg" v-else @click="$router.push('/login')" style="width: 20px; height: 20px;"/>
            </div>
          </div>
        </div>
      <div class="mobileHeader__burgerBack" v-if="$store.state.openBurger == true"> 
        <div class="mobileHeader__burger">
          <div class="mobileHeader__burger__top">
            <div>
              <img src="@/assets/icons/burgerblack.svg">
              <router-link to="/catalog" style="text-decoration: none; color: black"><p>Каталог товаров</p></router-link>
            </div>
            <div>
              <img src="@/assets/icons/starblack.svg">
              <router-link to="/account" @click="$store.state.openAcc = 'favourites'" style="text-decoration: none; color: black"><p>Избранное</p></router-link>
            </div>
            <div>
              <img src="@/assets/icons/prophileblack.svg">
              <router-link to="/register" style="text-decoration: none; color: black"><p style="display: flex; gap:5px;">Вход <span class="line2"></span> Регистрация</p></router-link>
            </div>
          </div>
          <div class="line"></div>
          <div class="mobileHeader__burger__center">
            <img src="@/assets/icons/basketblack.svg">
            <router-link to="/account" @click="$store.state.openAcc = 'basket'" style="text-decoration: none; color: black"><p>Корзина</p></router-link>
          </div>
          <div class="line"></div>
          <div class="mobileHeader__burger__bot">
            <router-link to="/">Главная</router-link>
            <router-link to="/instructions">Инструкции</router-link>
            <router-link to="/delivery">Дилеры</router-link>
            <router-link to="/about">О нас</router-link>
            <router-link to="/dealers">Доставка</router-link>
            <router-link to="/contacts">Контакты</router-link>
          </div>
        </div>
      </div>
      </div>
      </div>
      <div class="mobileHeader__logo" v-if="$route.meta.info == false">
        <img src="@/assets/icons/mobileLogo.svg" @click="$router.push('/')"/>
      </div>
      <div class="mobileHeader__bot container" v-if="$route.meta.info == false">
        <div class="mobileHeader__bot__search"> 
          <input type="search" placeholder="Search" @keydown.enter="searchODIT" v-model="searchQuery">
        </div>
        <div class="mobileHeader__bot__button">
          <button @click="searchODIT">Поиск</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import router from '@/router'
import { toast } from 'vue3-toastify';
import axios from 'axios';
import { mapGetters } from 'vuex';
import 'vue3-toastify/dist/index.css';
import { onMounted, onBeforeUnmount } from 'vue';
export default {
  data() {
    return {
      header: false,
      openModalLogin: false,
      header: false,
      searchQuery: "",
      searchResults: []
    }
  },
  async created() {
    this.requestUser();
    this.getPage()
  },
  methods: {
    goResult() {
      localStorage.setItem('searchResult', JSON.stringify(this.searchResults))
      this.$router.push({ name: 'allresults', query: { search: this.searchQuery } })
      setTimeout(() => {
        this.searchQuery = "",
          this.searchResults = []
      }, 300);
    },
    makeCall() {
      const phoneNumber = 123;
      if (phoneNumber) {
        window.location.href = `tel:${phoneNumber}`;
      } else {
        console.error("Номер телефона не найден");
      }
    },
    handleScroll() {
      // Проверяем, насколько прокручена страница
      this.isScrolledDown = window.scrollY > 0;
    },
    searchODIT() {
      const apiUrl = `https://back-hayward.mydev.kz/api/V1/pages/products/search?text=${encodeURIComponent(this.searchQuery)}`;
      axios.get(apiUrl)
        .then(response => {
          // Сохраните результаты поиска в searchResults
          this.searchResults = response.data.products;
          this.goResult()
        })
        .catch(error => {
          // Обработайте возможные ошибки
          toast.error('Поле для поиска обязательна')
        });
    },
    setActiveTab(route) {
      const tabs = ['/', '/catalog', '/instructions', '/payment', '/delivery', '/about', '/dealers', '/contacts'];
      const index = tabs.indexOf(route);
      const inputId = `radio-${index + 1}`;
    },
    ...mapActions(['requestUser']),
    ...mapActions(["logoutUser"]),
    async getPage() {
      await this.$axios.get(`get-header?lang=ru`)
        .then(res => {
          this.header = res.data
        })
        .catch(err => {
        })
    },
    leaveAccount() {
      this.logoutUser();
      toast("Вы вышли с аккаунта.");
      this.$router.push('/')
    }
  },
  computed: {
    ...mapGetters(['getBasket']),
    ...mapGetters(['getLiked']),
  },
  beforeDestroy() {
    // Удаляем слушатель события перед разрушением компонента
  },
  mounted() {
    this.setActiveTab(this.$route.path);
    var mobileHeaderFixed = this.$refs.mobileHeaderFixed;
    var elementHeight = mobileHeaderFixed.clientHeight;
    this.$store.state.fixedBlock = elementHeight;
  },
  watch: {
    $route(to) {
      this.setActiveTab(to.path);
      this.$store.state.openBurger = false
    },
    '$store.state.openBurger': function (newValue) {
      // newValue содержит новое значение $store.state.openBurger
      if (newValue) {
        document.documentElement.style.overflow = 'hidden';
      } else {
        document.documentElement.style.overflow = 'auto'; // Восстановление стандартного значения
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.line2{
  border: 1px solid rgb(77, 75, 75);
}
.mobileHeaderFixed{
  z-index: 999;
  position: fixed;
  top: 0;
  width: 100%;
}
.info__right__top__icons__search input[type="search"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  /* Добавьте другие необходимые стили */
}

.info__right__top__icons__search input[type="search"]::-webkit-search-cancel-button {
  display: none;
}
.activeLink{
  color: red !important;
}
.hoveredElements{
  transition: all 0.5s ease;
  &:hover{
    transform: scale(1.04);
  }
}
.headerBack {
  background: #006495;
}
.line1{
  border: 1px solid #c4b6b6c9;
}
.mobileHeader{
  display: flex;
  flex-direction: column;
  &__centerBack{
    background: #006495;
    position: relative;
    width: 100%;
  }
  &__center{
    display: flex;
    justify-content: space-between;
    padding: 20px 30px;
  }
  &__burgerBack{
    position: absolute;
    top: 100%;
    z-index: 999;
    background: rgba(0, 0, 0, 0.295);
    width: 100%;
    height: 100vh;
    font-size: 14px;
  }
  &__burger{
    background: white;
    width: 80%;
    display: flex;
    flex-direction: column;
    padding: 30px 25px;
    border-radius: 0px 10px 10px 0px;
    gap: 15px;
    &__top{
      display: flex;
      flex-direction: column;
      gap: 20px;
      color: #000000;
      div{
        display: flex;
        gap: 10px;
        align-items: center;
      }
    }
    &__center{
      display: flex;
      align-items: center;
      gap: 10px;
    }
    &__bot{
      display: flex;
      flex-direction: column;
      gap: 10px;
      a{
        text-decoration: none;
        color: black;
      }
    }
  }
  &__topBack{
    background: #006495;
    position: relative;
  }
  &__top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__left{
      display: flex;
      gap: 15px;
      align-items: center;
      &__city{
        display: flex;
        gap: 10px;
        align-items: center;
        select {
          background: #006495;
          color: white;
          width: 80px;
          border: none;
          outline: none;
          font-size: 13px;
        }
      }
    }
    &__right{
        display: flex;
        gap: 10px;
        align-items: center;
        select {
          background: #006495;
          color: white;
          width: 80px;
          border: none;
          outline: none;
          font-size: 13px;
        }
    }
  }
  &__logo{
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    margin-top: 30px;
    img{
      width: 100%;
    }
  }
  &__bot{
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    &__search{
          position: relative;
          width: 70%;
          input {
            border: 1px solid #00000033;
            border-radius: 10px;
            outline: none;
            width: 100%;
            padding: 10px 30px 10px 10px;
            font-size: 13px;
          }
    }
    &__button{
      width: 27%;
      button{
        width: 100%;
        padding: 10px;
        font-size: 13px;
        cursor: pointer;
        border-radius: 10px;
        border: none;
        background: #006495;
        color: white;
      }
    }
  }
}
.header {
  padding: 8px 0px;
  display: flex;
  justify-content: space-between;
  color: white;
  font-size: min(max(9px, calc(0.5625rem + ((1vw - 7.68px) * 0.6076))), 16px);
  align-items: center;
  &__left {
    &__city {
      select {
        background: #006495;
        font-size: min(max(9px, calc(0.5625rem + ((1vw - 7.68px) * 0.6076))), 16px);
        color: white;
        border: none;
        outline: none;
      }
    }
    display: flex;
    gap: min(max(8px, calc(0.5rem + ((1vw - 7.68px) * 0.6076))), 15px);
    align-items: center;
    div {
      display: flex;
      gap: 5px;
      align-items: center;
    }
    &__info {
      text-decoration: underline;
      display: flex;
      align-items: center;
    }
  }

  &__right {
    display: flex;
    gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 1.3021))), 30px);
    &__1 {
      display: flex;
      gap: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.8681))), 20px);
    }
  }
}

.tabs {
  display: flex;
  position: relative;
  background-color: #fff;
  box-shadow: 0 0 1px 0 rgba(24, 94, 224, 0.15), 0 6px 12px 0 rgba(24, 94, 224, 0.15);
  padding:  min(max(0.3rem, calc(0.3rem + ((1vw - 0.48rem) * 0.625))), 0.75rem);
  border-radius: 99px;
}

.tabs * {
  z-index: 2;
}

.cont input[type="radio"] {
  display: none;
}

.tab {
  display: flex;
  align-items: center;
  justify-content: center;
  height: min(max(20px, calc(1.25rem + ((1vw - 7.68px) * 1.7361))), 40px);
  width:  min(max(70px, calc(4.375rem + ((1vw - 7.68px) * 6.0764))), 140px);
  font-size:  min(max(13px, calc(0.8125rem + ((1vw - 7.68px) * 0.434))), 18px);
  text-align: center;
  color: black;
  font-weight: 500;
  border-radius: 99px;
  cursor: pointer;
  transition: all 0.5 ease;
  &:hover{
    transform: scale(1.04);
    color: #185ee0;
  }
}

.cont input[type="radio"]:checked+label {
  color: #185ee0;
}

.cont input[type="radio"]:checked+label>.notification {
  background-color: #185ee0;
  color: #fff;
  margin: 0px;
}

.tabs input[id="radio-1"]:checked~.glider {
  transform: translateX(0);
}

.cont input[id="radio-2"]:checked~.glider {
  transform: translateX(100%);
}

.cont input[id="radio-3"]:checked~.glider {
  transform: translateX(200%);
}

.cont input[id="radio-4"]:checked~.glider {
  transform: translateX(300%);
}

.cont input[id="radio-5"]:checked~.glider {
  transform: translateX(400%);
}

.cont input[id="radio-6"]:checked~.glider {
  transform: translateX(500%);
}

.cont input[id="radio-7"]:checked~.glider {
  transform: translateX(600%);
}

.cont input[id="radio-8"]:checked~.glider {
  transform: translateX(700%);
}

.glider {
  position: absolute;
  display: flex;
  height:  min(max(20px, calc(1.25rem + ((1vw - 7.68px) * 1.7361))), 40px);
  width: min(max(70px, calc(4.375rem + ((1vw - 7.68px) * 6.0764))), 140px);
  background-color: #e6eef9;
  z-index: 1;
  border-radius: 99px;
  transition: 0.25s ease-out;
}

@media (max-width: 700px) {
  .tabs {
    transform: scale(0.6);
  }
}

.heart {
  position: relative;
  display: flex;
  align-items: center;
  &__img{
    width:  min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 1.0417))), 24px);
    height:  min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 1.0417))), 24px);
    @media(max-width:768px){
      width: 20px;
      height: 20px;
    }
  }
  &__in {
    width: min(max(9px, calc(0.5625rem + ((1vw - 7.68px) * 0.5208))), 15px);
    height: min(max(9px, calc(0.5625rem + ((1vw - 7.68px) * 0.5208))), 15px);
    background: red;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: white;
    font-size: 11px;
    position: absolute;
    top: -20%;
    right: -30%;
  }
  &__inMobile{
    width: 12px;
    height: 12px;
    background: red;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: white;
    font-size: 11px;
    position: absolute;
    top: -5%;
    right: -25%;
  }
}

.basket {
  position: relative;
  display: flex;
  align-items: center;
  &__img{
    width:  min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 1.3021))), 30px);
    height:  min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 1.3021))), 30px);
  }
  &__in {
    width: min(max(9px, calc(0.5625rem + ((1vw - 7.68px) * 0.5208))), 15px);
    height: min(max(9px, calc(0.5625rem + ((1vw - 7.68px) * 0.5208))), 15px);
    background: red;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: white;
    font-size: 11px;
    position: absolute;
    top: -5%;
    right: -25%;
  }
  &__inMobile{
    width: 12px;
    height: 12px;
    background: red;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: white;
    font-size: 11px;
    position: absolute;
    top: -5%;
    right: -25%;
  }
}

.profileImg {
  width: min(max(20px, calc(1.25rem + ((1vw - 7.68px) * 1.7361))), 40px);
  height: min(max(20px, calc(1.25rem + ((1vw - 7.68px) * 1.7361))), 40px);
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}

.line {
  border: 1px solid #0000001A;
}

.info {
  display: flex;
  justify-content: space-between;
  padding-top: min(max(30px, calc(1.875rem + ((1vw - 7.68px) * 2.6042))), 60px);
  align-items: center;
  &__left {
    width: 22.63%;
    font-size: 42px;
    img{
      width: 100%;
    }
  }
  &__right {
    width: 74.07%;
    display: flex;
    flex-direction: column;
    gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 1.3021))), 30px);
    &__top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
      &__texts {
        display: flex;
        gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 1.3021))), 30px);
        color: #363636;
        font-size: min(max(9px, calc(0.5625rem + ((1vw - 7.68px) * 0.5208))), 15px);
      }
      &__icons {
        display: flex;
        gap: min(max(17px, calc(1.0625rem + ((1vw - 7.68px) * 1.5625))), 35px);
        align-items: center;
        &__search {
          position: relative;
          input {
            border: 1px solid #00000033;
            font-size: min(max(8px, calc(0.5rem + ((1vw - 7.68px) * 0.5208))), 14px);
            border-radius: 10px;
            outline: none;
            padding: 5px min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px) 5px 10px;
            width: 100%;
          }
          button {
            position: absolute;
            top: 50%;
            right: 5px;
            transform: translateY(-50%);
            width: min(max(9px, calc(0.5625rem + ((1vw - 7.68px) * 0.5208))), 15px);
            /* Установите ширину и высоту кнопки по вашему желанию */
            height: min(max(9px, calc(0.5625rem + ((1vw - 7.68px) * 0.5208))), 15px);
            background: url('@/assets/icons/search.svg') no-repeat center center;
            /* Укажите путь к вашей картинке */
            background-size: contain;
            /* Размер изображения */
            border: none;
            padding: 0;
            cursor: pointer;
          }
          &__block {
            position: absolute;
            border: 1px solid #00000033;
            width: 100%;
            top: 150%;
            border-radius: 10px;
            padding: 5px min(max(5px, calc(0.3125rem + ((1vw - 0.07px) * 62.5))), 10px);
            z-index: 999;
            background: white;
          }
        }
        &__auth {
          display: flex;
          flex-direction: column;
          position: relative;
          &__in {
            position: absolute;
            top: 150%;
            z-index: 100;
            background: white;
            border: 1px solid #3636361A;
            padding: min(max(5px, calc(0.3125rem + ((1vw - 7.68px) * 0.434))), 10px) min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 1.1285))), 25px);
            display: flex;
            flex-direction: column;
            gap: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.8681))), 20px);
            text-align: center;
            border-radius: 10px;
            transform: translate(-45%, 0%);
            white-space: nowrap;
            p {
              font-size: min(max(9px, calc(0.5625rem + ((1vw - 7.68px) * 0.5208))), 15px);
              cursor: pointer;
              &:hover {
                color: #1080C1;
                transition: all 0.5s ease;
              }
            }
          }
        }
      }
    }
  }
}</style>