<template> 
  <div class="load-row Loader" v-show="getLoading">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
  </div>
  <div :style="getAppStyles" v-show="!getLoading">
    <Header />
    <router-view class="main"/> 
    <Footer />
  </div> 
  <button class="button" @click="srcollUp()">
  <svg class="svgIcon" viewBox="0 0 384 512">
    <path
      d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z"
    ></path>
  </svg>
</button>
</template> 
 
<script> 
import Header from './components/layouts/Header.vue';
import Footer from './components/layouts/Footer.vue';
import { mapGetters } from 'vuex';
export default { 
  components: {Header,Footer},
  methods: {
    srcollUp() { 
            window.scrollTo({ 
            top: 0, 
            behavior: 'smooth' 
            }); 
        }
  },
  watch: {
        $route(){
            this.$store.state.openBasket = 'basket';
            window.scrollTo({ 
            top: 0, 
            behavior: 'smooth' 
            }); 
        }
        
    },
    computed: {
    getAppStyles() {
      if (window.innerWidth <= 768) {
        return { marginTop: (this.$store.state.fixedBlock + 20) + 'px' };
      } 
    },
    ...mapGetters(['getLoading'])
  }
} 
 
</script> 
 
<style lang="scss"> 
.no-scroll {
  overflow: hidden;
}
.Loader {
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;

}
.load-row {
  width: 100px;
  height: 50px;
  line-height: 50px;
  text-align: center;
}

.load-row span {
  display: inline-block;
  width: 15px;
  height: 15px;
  background: #434cc6;
  border-radius: 50px;
  animation: up-down6 0.5s ease-in infinite alternate;
}

.load-row span:nth-child(2) {
  background: #434cc6;
  animation-delay: 0.16s;
}

.load-row span:nth-child(3) {
  background: #434cc6;
  animation-delay: 0.32s;
}

.load-row span:nth-child(4) {
  background: #434cc6;
  animation-delay: 0.48s;
}

@keyframes up-down6 {
  0% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(10px);
  }
}
#app { 
min-height: 100vh; 
display: flex; 
flex-direction: column; 
align-items: stretch; 
} 
*{
  box-sizing: border-box !important;
}
html,body,p { 
  margin: 0 ; 
  padding: 0 ; 
} 
.button {
  width: 50px;
  position: fixed;
  height: 50px;
  border-radius: 50%;
  background-color: rgb(250, 250, 250);
  border: none;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 0px 4px rgba(44, 102, 189, 0.712);
  cursor: pointer;
  transition-duration: 0.3s;
  overflow: hidden;
  bottom: 5%;
  right: 2%;
  @media(max-width:768px){
    display: none;
  }
}

.svgIcon {
  width: 12px;
  transition-duration: 0.3s;
}

.svgIcon path {
  fill: rgb(2, 2, 2);
}
.main{
  @media(max-width:768px){
          margin-top: 50px;
        }
}

</style>
