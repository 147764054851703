    <template>
    <div class="card">
        <Bread :title1="'Каталог товаров'" :title2="data.title" :link1="'/catalog'" class="container"/>  
        <div class="card__top container" id="all">
            <div class="card__top__left">
                <div class="card__top__left__title">
                    <p>{{ data.title }}</p>
                </div>
                <div class="mobile">
                    <div class="bread" :class="{ 'fixedBread': isBreadFixed }">
                        <div class="bread__all" :class="{customBread: selectedBread == 1}" @click="selectedBread = 1, scrollToElement('all')">
                            <p>Все о товаре</p>
                        </div>
                        <div class="bread__desc" :class="{customBread: selectedBread == 3}" @click="selectedBread = 3, scrollToElement('desc')">
                            <p>Описание</p>
                        </div>
                        <div class="bread__char" :class="{customBread: selectedBread == 2}" @click="selectedBread = 2, scrollToElement('char')">
                            <p>Характеристики</p>
                        </div>
                    </div>
                </div>
                <div class="card__top__left__images">
                    <div class="card__top__left__images__mini">
                        <div class="card__top__left__images__mini__in" v-for="item in data.images" :key="item.id">
                            <img :src="item.image" @click="selectImage = item.image" :class="{selectedImage: item.image == selectImage}">
                        </div>
                    </div>
                    <div class="card__top__left__images__max">
                        <vue-image-zoomer 
	                        :regular="selectImage"/>
                    </div>
                </div>
            </div>
            <div class="card__top__right">
                <div class="card__top__right__1">
                    <div class="card__top__right__1__left">
                        <div class="card__top__right__1__left__b1">
                            <div>
                                В наличии
                            </div>
                            <p>Код товара: {{ data.vendor_code }}</p>
                        </div>
                        <div class="card__top__right__1__left__b2">
                            <p v-if="data.price !== 0">{{ data.price }} Т</p>
                            <p v-else>ПО ЗАПРОСУ</p>
                        </div>
                        <div class="card__top__right__1__left__b4">
                            <button  @click="addBasket(data)">
                                <p v-if="!isBasket">В корзину</p>
                                <p v-else>Убрать из корзины</p>
                            </button>
                            <div>
                                <img src="@/assets/icons/heart3.svg" @click="addFavour(data)" :class="{newHeart: isLiked}" class="heart"/>
                                <p v-if="!isLiked">В избранное</p>
                                <p v-else>Удалить из избранного</p>
                            </div>
                        </div>
                    </div>
                    <div class="line"></div>
                    <div class="card__top__right__1__right">
                        <div class="card__top__right__1__right__in" v-for="card in right" :key="card">
                            <img :src="require('@/assets/icons/' + card.img + '.svg')">
                            <p>{{ card.name }}</p>
                        </div>
                    </div>
                </div>
                <div class="line"></div>
                <div class="card__top__right__2">
                    <p v-html="data.description"></p>
                </div>
            </div>
        </div>
        <div v-html="data.instruction" class="container" id="desc"></div>
        <div class="char container">
            <div class="char__title" id="char" v-if="data?.productFeatureItems?.length > 0">
                <p>Характеристики</p>
            </div>
            <div class="char__instruction" v-if="data.document">
                <p class="char__instruction__left">Скачиваемые материалы</p>
                <p class="char__instruction__right" @click="downloadFile">Инструкция</p>
            </div>
            <div class="char__block" v-if="data?.productFeatureItems?.length > 0">
                <div class="char__block__in" v-for="(item, index) in data.productFeatureItems" :key="index" :class="{ 'customChar': index % 2 !== 1 }">
                    <p class="char__block__in__left">{{  Object.keys(item)[0] }}</p>
                    <p class="char__block__in__right">{{  Object.values(item)[0] }}</p>
                </div>
            </div>
        </div>
    </div>
    <div class="more container">
            <p>Похожие товары</p>
            <Products :productsArray="slider" v-if="slider !== ''"/>
    </div>

</template>
<script>
import Products from '@/components/Products.vue';
import Search from '@/components/Search.vue';
import Bread from '@/components/Bread.vue';
import { Swiper, SwiperSlide,} from "swiper/vue"; 
import { VueImageZoomer } from 'vue-image-zoomer'
import 'vue-image-zoomer/dist/style.css';
import "swiper/css"; 
import "swiper/css/pagination"; 
import "swiper/css/navigation";  
import { mapActions, mapGetters } from 'vuex';
import SwiperCore, { A11y, Autoplay } from "swiper"; 
import { Navigation, Pagination } from 'swiper'; 
import "swiper/swiper.min.css"; 
SwiperCore.use([Navigation,Pagination, A11y, Autoplay])
export default {
    components:{
    Products, Swiper, SwiperSlide, Search, Bread, VueImageZoomer
  },
  data(){
    return{
        selectImage: 'photo1',
        data: false,
        selectedBread: 1,
        isBreadFixed: false,
        slider: '',
        choose: 2,
    }
  },
  methods: {
    handleScroll() {
        if (this.$store.state.openBurger === true) {
            this.isBreadFixed = false;
            return; // чтобы избежать выполнения остального кода в методе, если openBurger === true
        }

        if (window.scrollY > 100) {
            this.isBreadFixed = true;
        } else {
            this.isBreadFixed = false;
        }
    },
    scrollToElement(elementId) {
        const element = document.getElementById(elementId);
        if (element) {
            element.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    },
    downloadFile() {
      const fileName = this.data.document;
      const fileUrl = this.data.document;

      // Создаем ссылку на загрузку файла
      const link = document.createElement('a');
      link.href = fileUrl;
      link.download = fileName;

      // Добавляем ссылку на страницу и эмулируем клик
      document.body.appendChild(link);
      link.click();

      // Удаляем ссылку из документа после скачивания
      document.body.removeChild(link);
    },
    ...mapActions(['addBasket']),
    ...mapActions(['addFavour']),
    async getPage() {    
            await this.$axios.get(`pages/product?slug=${this.$route.params.slug}`)
            .then(res => {
                this.data = res.data.product
                this.slider = res.data.similarProducts
                this.selectImage = res.data.product.images[0].image
            })
            .catch(err => {
            })        
        },
        calculateSlidesPerView(numItems) {
            return numItems < 4 ? 3 : 4;
        }
    },
    watch: {
        $route(){
            this.getPage();
            this.slider = '';
            this.choose = 1;
        },
        '$store.state.openBurger'(newValue) {
            this.isBreadFixed = !newValue; // Если newValue === false, то isBreadFixed будет true, и наоборот
        }
    },
    async created() {
        this.getPage()    
    },
    computed: {
        ...mapGetters(['getBasket']),
        isBasket() {
            return this.getBasket.some(item => item.id === this.data.id)
        },
        ...mapGetters(['getLiked']),
        isLiked() {
            return this.getLiked.some(card => card.id === this.data.id);
        },
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    
}
</script>
<style lang="scss" scoped>
.fixedBread {
    position: fixed;
    top: 0;
    left: 0;
    margin-top: 63px;
    background: white;
    width: 100%;
    z-index: 1000; /* или любое другое значение, чтобы убедиться, что блок bread находится над остальным содержимым */
    /* добавьте любые другие стили, которые вам нужны */
}
.customChar{
    background: #FBFBFB !important;
}
.customBread{
    color: #3AA3FE;
    border-bottom: 5px solid #3AA3FE !important;
}
.bread{
    display: flex;
    justify-content: space-between;
    padding: 15px 0px 0px 0px;
    div{
        width: 33.33%;
        padding-bottom: 20px;
        text-align: center;
        font-size: 14px;
        border-bottom: 5px solid grey;
        cursor: pointer;
    }
}
.char{
    display: flex;
    flex-direction: column;
    gap: 20px;
    &__instruction{
        width: 50%;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        &__left{
            width: 50%;
        }
        &__right{
            width: 50%;
            color: #3AA3FE;
            font-weight: 700;
            cursor: pointer;
        }
    }
    &__block{
        display: flex;
        font-size: 14px;
        flex-direction: column;
        &__in{
            display: flex;
            justify-content: space-between;
            width: 50%;
            padding: 15px 0px;
            &__left{
                width: 50%;
            }
            &__right{
                width: 50%;
            }
        }
    }
    @media(max-width:768px){
            &__instruction{
                width: 100%;
                &__left{
                    width: fit-content;
                }
                &__right{
                    width: fit-content;
                }
            }
            &__block{
                &__in{
                    width: 100%;
                    &__left{
                        width: fit-content;
                    }
                    &__right{
                        width: fit-content;
                    }
                }
            }
        }
}
.imageZoom{
    display: none;
}
.line{
    border: 1px solid #0000001A;
}
.blueLine{
    border: 6px solid #0076FF;
    border-radius: 5px;
}
.heart{
    &:hover{
        transform: scale(1.1);
        transition: all 0.2s ease;
        cursor: pointer;
    }
}
.newHeart{
    filter: brightness(0) saturate(100%) invert(17%) sepia(78%) saturate(3656%) hue-rotate(336deg) brightness(90%) contrast(102%);
    transition: all 0.5s ease;
    cursor: pointer;
}
.more{
    padding-top: min(max(20px, calc(1.25rem + ((1vw - 7.68px) * 1.7361))), 40px);
    padding-bottom: min(max(20px, calc(1.25rem + ((1vw - 7.68px) * 1.7361))), 40px);
    display: flex;
    flex-direction: column;
    gap: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px);
    font-size: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.7813))), 24px);
    @media(max-width: 768px){
        font-size: 17px;
    }
}
.selectedImage{
    border: 1px solid #006495;
}
.card{
    padding: 50px 0px;
    display: flex;
    flex-direction: column;
    gap: 50px;
    &__top{
        display: flex;
        justify-content: space-between;
        &__left{
            width: 38%;
            display: flex;
            flex-direction: column;
            gap: 30px;
            &__title{
                font-size: 24px;
            }
            &__images{
                display: flex;
                justify-content: space-between;
                &__mini{
                    width: 13%;
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    img{
                        width: 100%;
                        aspect-ratio: 1;
                        box-shadow: 0px 0px 1px 0px #0000004D;
                        border-radius: 4px;
                        cursor: pointer;
                    }
                }
                &__max{
                    width: 82%;
                }
            }
        }
        &__right{
            width: 53%;
            display: flex;
            flex-direction: column;
            gap: 15px;
            &__1{
                display: flex;
                justify-content: space-between;
                &__left{
                    width: 54%;
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    &__b1{
                        display: flex;
                        justify-content: space-between;
                        font-size: 15px;
                        align-items: center;
                        div{
                            color: #00A046;
                            background: #EAFCF0;
                            padding: 5px 15px;
                            border-radius: 10px;
                        }
                        p{
                            font-weight: 700;
                        }
                    }
                    &__b2{
                        font-size: 30px;
                        font-weight: 700;
                    }
                    &__b3{
                        font-size: 15px;
                        display: flex;
                        flex-direction: column;
                        gap: 15px;
                        div{
                            border: 1px solid #006495;
                            width: fit-content;
                            padding: 5px;
                            border-radius: 5px;
                            cursor: pointer;
                        }
                    }
                    &__b4{
                        display: flex;
                        gap: 30px;
                        align-items: center;
                        font-size: 15px;
                        button{
                            height: 40px;
                            width: 30%;
                            background: #006495;
                            color: white;
                            border-radius: 10px;
                            border: none;
                            cursor: pointer;
                            font-size: 15px;
                        }
                        div{
                            display: flex;
                            gap: 10px;
                            align-items: center;
                        }
                    }
                }
                &__right{
                    width: 36.5%;
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                    font-size: 15px;
                    justify-content: center;
                    &__in{
                        display: flex;
                        gap: 10px;
                        align-items: center;
                    }
                }
            }
            &__2{
                font-size: 14px;
                display: flex;
                flex-direction: column;
                gap: 15px;
            }
        }
    }
    &__block1{
        display: flex;
        justify-content: center;
        gap: 50px;
        font-size: 14px;
        text-align: center;
        &__in{
            width: 13.5%;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 15px;
            img{
                width: 35%;
                aspect-ratio: 1;
            }
            &__name{
                font-weight: 600;
            }
        }
    }
    &__block2Back{
        background: url('@/assets/icons/back.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
    &__block2{
        display: flex;
        flex-direction: column;
        gap: 50px;
        padding: 80px 0px 160px;
        &__top{
            display: flex;
            justify-content: space-between;
            &__left{
                width: 59%;
                display: flex;
                flex-direction: column;
                gap: 20px;
                color: white;
                &__title{
                    font-size: 30px;
                }
                &__text{
                    font-size: 15px;
                    line-height: 25px;
                }
            }
            &__right{
                width: 27%;
                img{
                    width: 100%;
                    aspect-ratio: 1.44;
                }
            }
        }
        &__bot{
            display: flex;
            gap: 30px;
            &__in{
                display: flex;
                width: 14%;
                gap: 15px;
                &__right{
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    font-weight: 700;
                    &__value{
                        font-size: 30px;
                        color: #0076FF;
                    }
                    &__name{
                        color: white;
                        font-size: 15px;
                    }
                }
            }
        }
    }
    &__block3Back{
        background: #232F3E;
    }
    &__block3{
        padding: 50px 0px;
        display: flex;
        flex-direction: column;
        width: 54%;
        font-size: 30px;
        gap: 30px;
        align-items: center;
        color: white;
        img{
            width: 100%;
            aspect-ratio: 1.55  ;
        }
        &__table{
            width: 100%;
        }
    }
    &__block4{
        display: flex;
        flex-direction: column;
        gap: 30px;
        font-size: 30px;
        align-items: center;
        width: 54%;
        margin: 0 auto;
        img{
            width: 100%;
            aspect-ratio: 2.16;
        }
    }
    &__block5Back{
        background: #232F3E;
    }
    &__block5{
        padding: 50px 0px;
        display: flex;
        flex-direction: column;
        gap: 30px;
        align-items: center;
        p{
            font-size: 30px;
            color: white;
        }
        &__bot{
            display: flex;
            justify-content: center;
            gap: 70px;
            &__left{
                width: 38%;
                img{
                    width: 100%;
                    aspect-ratio: 1.4;
                }
            }
            &__left{
                width: 38%;
                aspect-ratio: 1.6;
            }
        }
    }
    &__block6{
        width: 50%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        gap: 30px;
        font-size: 30px;
        align-items: center;
        img{
            width: 100%;
            aspect-ratio: 1.73;
        }
    }
    @media(max-width:768px){
        padding: 20px 0px !important;
        gap: 25px;
        &__top{
            flex-direction: column;
            gap: 20px;
            &__left{
                width: 100%;
                flex-direction: column;
                &__title{
                    font-size: 17px;
                }
                &__images{
                    flex-direction: column-reverse;
                    gap: 20px;
                    &__mini{
                        flex-direction: row;
                        width: 100%;
                        justify-content: space-between;
                        &__in{
                            width: 20%;
                        }
                    }
                    &__max{
                        width: 100%;
                    }
                }
            }
            &__right{
                width: 100%;
                &__1{
                    flex-direction: column;
                    gap: 20px;
                    &__left{
                        width: 100%;
                        &__b4{
                            button{
                                width: 50%;
                            }
                        }
                    }
                    &__right{
                        width: 100%;
                    }
                }
            }
        }
        &__block1{
            display: none;
        }
        &__block2Back{
            background: #232F3E;
        }
        &__block2{
            padding: 20px 0px;
            &__top{
                flex-direction: column;
                gap: 30px;
                &__left{
                    width: 100%;
                }
                &__right{
                    width: 100%;
                }
            }
            &__bot{
                flex-direction: column;
                &__in{
                    width: 100%;
                }
            }
        }
        &__block3Back{
            padding: 20px 0px;
        }
        &__block3{
            padding: 0px;
            width: 95%;
            &__title{
                font-size: 15px;
                text-align: center;
            }
        }
        &__block4{
            padding: 20px 0px;
            width: 95%;
            font-size: 15px;
        }
        &__block5Back{
            padding: 0;
        }
        &__block5{
            padding: 20px 0px;
            p{
                font-size: 15px;
            }
            &__bot{
                flex-direction: column;
                &__left{
                    width: 100%;
                }
            }
        }
        &__block6{
            width: 95%;
            p{
                font-size: 15px;
            }
        }
    }
}
</style>