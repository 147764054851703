<script>
export default {
    props: ['title', 'min', 'max', 'step', 'range', 'index'],

    data() {
        return {
            priceGap: 50,
            currentMin: 0,
            currentMax: 100,
        }
    },

    mounted() {
        this.currentMin = this.range[0];
        this.currentMax = this.range[1];
        this.priceGap = this.step;

        const range = document.querySelector(`.rangePrice__range__slider${this.index} .rangePrice__range__slider-progress${this.index}`);
        if(this.currentMin > this.min){
            range.style.left = ((this.currentMin - this.min) / (this.max - this.min)) * 100 + "%";
        }
        else{
            range.style.left = 0 + "%"
        }
        range.style.right = 100 - ((this.currentMax - this.min) / (this.max - this.min)) * 100 + "%";
    },

    watch: {
        currentMin(newVal, oldVal) {
            if (newVal > this.currentMax) {
                this.currentMax = newVal;
            }
        },
        currentMax(newVal, oldVal) {
            if (newVal < this.currentMin) {
                this.currentMin = newVal;
            }
        },
        '$store.state.ranges': {
            handler: function(newVal, oldVal) {
                this.featureItemRanges(newVal);
            },
            deep: true, // если ranges - объект, следите за изменениями внутри него
            },
    },

    methods: {
        featureItemRanges(value) {
            const currentQuery = { ...this.$route.query };
            currentQuery.feature_item_ranges = JSON.stringify(value);
            this.$router.push({ path: '/catalog', query: { ...currentQuery } });
        },
        sendValue(min, max) {
            this.$emit('getValueWithRange', min, max);
            const newRange = {
                id: this.$store.state.range.id,
                min: min,
                max: max
            };
            const existingIndex = this.$store.state.ranges.findIndex(item => item.id === newRange.id);
            if (existingIndex !== -1) {
                this.$store.state.ranges[existingIndex] = newRange
            } else {
                this.$store.state.ranges.push(newRange);
            }
        },

        rangeSwipe(e) {
            const range = document.querySelector(`.rangePrice__range__slider${this.index} .rangePrice__range__slider-progress${this.index}`);

            let minVal = parseInt(this.currentMin),
                maxVal = parseInt(this.currentMax);

            if (maxVal - minVal < this.priceGap) {
                if (e.target.className === "range-min") {
                    this.currentMin = maxVal - parseInt(this.priceGap);
                }
                else {
                    this.currentMax = minVal + parseInt(this.priceGap);
                }
            }
            else {
                this.currentMin = minVal;
                this.currentMax = maxVal;
                if(this.currentMin > this.min){
                    range.style.left = ((this.currentMin - this.min) / (this.max - this.min)) * 100 + "%";
                }
                else{
                    range.style.left = 0 + "%"
                }
                range.style.right = 100 - ((this.currentMax - this.min) / (this.max - this.min)) * 100 + "%";
            }
        },

        priceRange(e) {
            const priceInput = document.querySelectorAll(`.rangePrice__input${this.index} input`);
            const range = document.querySelector(`.rangePrice__range__slider${this.index} .rangePrice__range__slider-progress${this.index}`);

            let minPrice = parseInt(priceInput[0].value),
                maxPrice = parseInt(priceInput[1].value);

            if (maxPrice - minPrice >= this.priceGap && maxPrice <= this.max) {
                if (e.target.className === "input-min") {
                    this.currentMin = minPrice;
                    if(this.currentMin > this.min){
                     range.style.left = ((this.currentMin - this.min) / (this.max - this.min)) * 100 + "%";
                    }
                    else{
                        range.style.left = 0 + "%"
                    }
                }
                else {
                    this.currentMax = maxPrice;
                    range.style.right = 100 - ((this.currentMax - this.min) / (this.max - this.min)) * 100 + "%";
                }
            }
        },
    },
}
</script>

<template>
    <div class="rangePrice">
        <p class="rangePrice-title">{{ title }}</p>
        <div class="rangePrice__input" :class="`rangePrice__input${index}`">
            <input type="number" class="input-min" :max="currentMax - step" :value="currentMin"
                @input="priceRange($event)" />

            <div class="rangePrice__input-line"></div>

            <input type="number" class="input-max" :min="currentMin + step" :value="currentMax"
                @input="priceRange($event)" />

            <button @click="sendValue(this.currentMin, this.currentMax)">Да</button>
        </div>

        <div class="rangePrice__range">
            <div class="rangePrice__range__slider" :class="`rangePrice__range__slider${index}`">
                <div class="rangePrice__range__slider-progress" :class="`rangePrice__range__slider-progress${index}`"></div>
            </div>
        </div>

        <div class="rangePrice__inputBox">
            <input type="range" class="range-min" :min="min" :max="max" v-model="currentMin" :step="step"
                @input="rangeSwipe($event)" />

            <input type="range" class="range-max" :min="min" :max="max" v-model="currentMax" :step="step"
                @input="rangeSwipe($event)" />
        </div>

    </div>
</template>

<style lang="scss" scoped>
.rangePrice {
    display: flex;
    flex-direction: column;
    gap: min(max(9px, calc(0.5625rem + ((1vw - 7.68px) * 0.5208))), 15px);
    width: 100%;

    &-title {
        font-size: min(max(9px, calc(0.5625rem + ((1vw - 7.68px) * 0.5208))), 15px);
        font-weight: 500;
    }

    &__input {
        display: flex;
        align-items: center;
        gap: 10px;

        &-line {
            width: min(max(20px, calc(1.25rem + ((1vw - 7.68px) * 1.3021))), 35px);
            height: 2px;
            background: #36363626;
        }

        input {
            min-width: min(max(45px, calc(2.8125rem + ((1vw - 7.68px) * 3.0382))), 80px);
            width: fit-content;
            padding: 5px min(max(6px, calc(0.375rem + ((1vw - 7.68px) * 0.3472))), 10px);
            border: 1px solid rgba(54, 54, 54, 0.20);
            background: rgba(241, 241, 241, 0.50);
            border-radius: 3px;
            font-size: min(max(7px, calc(0.4375rem + ((1vw - 7.68px) * 0.5208))), 13px);
            outline: none;
        }

        button {
            padding: 5px min(max(6px, calc(0.375rem + ((1vw - 7.68px) * 0.3472))), 10px);
            border: 1px solid rgba(54, 54, 54, 0.20);
            background: rgba(241, 241, 241, 0.50);
            border-radius: 3px;
            font-size: min(max(9px, calc(0.5625rem + ((1vw - 7.68px) * 0.3472))), 13px);
        }
    }

    &__range {
        &__slider {
            height: 3px;
            position: relative;
            background: #ddd;
            border-radius: 5px;

            &-progress {
                height: 100%;
                left: 25%;
                right: 25%;
                position: absolute;
                border-radius: 5px;
                background: #282828;
            }
        }
    }

    &__inputBox {
        position: relative;
        top: -13px;

        input {
            cursor: pointer;
            position: absolute;
            width: 100%;
            height: 5px;
            top: -7px;
            left: -2px;
            background: none;
            pointer-events: none;
            -webkit-appearance: none;
            -moz-appearance: none;
        }

        input[type="range"]::-webkit-slider-thumb {
            height: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.6076))), 17px);
            width: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.6076))), 17px);
            border-radius: 50%;
            background: #282828;
            pointer-events: auto;
            -webkit-appearance: none;
            box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
        }

        input[type="range"]::-moz-range-thumb {
            height: min(max(8px, calc(0.5rem + ((1vw - 7.68px) * 0.7813))), 17px);
            width: min(max(8px, calc(0.5rem + ((1vw - 7.68px) * 0.7813))), 17px);
            border: none;
            border-radius: 50%;
            background: #282828;
            pointer-events: auto;
            -moz-appearance: none;
            box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
        }
    }
}
</style>