<template>
    <div class="pay container">
        <Table />
        <div class="pay__right">
            <div class="pay__right__title">
                <p>Способ оплаты</p>
            </div> 
            <div class="pay__right__block">
                <div class="pay__right__block__top">
                    <div class="pay__right__block__top__left">
                        <img src="@/assets/icons/warn.svg"/>
                    </div>
                    <div class="pay__right__block__top__right">
                        <div class="pay__right__block__top__right__title">
                            <p>{{data[0]?.title}}</p>
                        </div>
                        <div class="pay__right__block__top__right__text">
                            <p>{{data[0]?.description}}</p>
                            <img :src="data[0]?.image"/>
                        </div>
                    </div>
                </div>
                <div class="line"></div>
                <div class="pay__right__block__top">
                    <div class="pay__right__block__top__left">
                        <img src="@/assets/icons/warn.svg"/>
                    </div>
                    <div class="pay__right__block__top__right">
                        <div class="pay__right__block__top__right__title">
                            <p>{{data[1]?.title}}</p>
                        </div>
                        <div class="pay__right__block__top__right__text">
                            <p>{{data[1]?.description}}</p>
                            <img :src="data[1]?.image"/>
                        </div>
                    </div>
                </div>  
            </div> 
        </div>
    </div>
</template>
<script>
import Table from '@/components/Table.vue';
import Bread from '@/components/Bread.vue';
import { mapMutations } from 'vuex';
export default {
    data(){
        return{
            data: false,
        }
    },
    components:{
        Table, Bread
    },
    async created() {
        this.getPage()    
    },
    methods: {
        ...mapMutations(['SET_LOADING']),
      async getPage() {    
        this.SET_LOADING(true) 
            await this.$axios.get(`pages/payment?lang=ru`)
            .then(res => {
                this.data = res.data.paymentMethods
                this.SET_LOADING(false) 
            })
            .catch(err => {
                this.SET_LOADING(false) 
            })        
        },
    }
}
</script>
<style lang="scss" scoped>
.line{
    border: 1px solid #0000001A;
}
    .pay{
        display: flex;
        justify-content: space-between;
        padding-top: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 1.1285))), 25px);
        @media(max-width:768px){
                        padding-bottom: 20px;
                    }
        &__right{
            width: 74.07%;
            display: flex;
            flex-direction: column;
            gap: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 1.1285))), 25px);
            @media(max-width:768px){
                        width: 100%;
            }
            &__title{
                font-size: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.6944))), 23px);
                font-weight: 600;
                @media(max-width:768px){
                    font-size: 15px;
                }
            }
            &__block{
                border: 1px solid #0000001A; 
                padding: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px) min(max(80px, calc(5rem + ((1vw - 7.68px) * 17.8819))), 286px)  min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px) min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 1.3021))), 30px);
                display: flex;
                flex-direction: column;
                gap: min(max(20px, calc(1.25rem + ((1vw - 7.68px) * 1.7361))), 40px);
                @media(max-width:768px){
                        padding: 20px;
                        gap: 15px;
                        border-radius: 10px;
                }
                &__top{
                    display: flex;
                    gap: 10px;
                    &__left{
                        img{
                            width:  min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 1.1285))), 25px);
                            height:  min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 1.1285))), 25px);
                            @media(max-width:768px){
                                width: 20px;
                                height: 20px;
                            }
                        }
                    }
                    &__right{
                        display: flex;
                        flex-direction: column;
                        gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 1.3021))), 30px);
                        width: 90%;
                        @media(max-width:768px){
                            width:95%;
                            gap: 15px;
                        }
                        &__title{
                            font-size: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.8681))), 20px);
                            @media(max-width:768px){
                                font-size: 15px;
                            }
                        }
                        &__text{
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            color: #36363680;
                            font-size: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.8681))), 20px);
                            img{
                                width: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 3.0382))), 60px);
                                height: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.9097))), 40px);
                                @media(max-width:768px){
                                    width: 35px;
                                    height: 27px;
                                }
                            }
                            p{
                                width: 80%;
                            }
                            @media(max-width:768px){
                                gap: 15px;
                                font-size: 15px;
                            }
                        }
                    }
                }
            }
        }
    }
</style>